export default defineAppConfig({
  saasName: 'FSBD Demo',
  customSaas: false,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si la visibilidad es oculto o público se asigna internamente a Taohirata.'
    }
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  analyticsLooker: '7b2393fb-a61c-4eec-ac11-710d9abbe2d2',
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      }
    ]
  }
})